import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
// import IconButton from '@material-ui/core/IconButton';
// import MenuIcon from '@material-ui/icons/Menu';

// import { Event } from './Tracking';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function NavBar() {
  const [open, setOpen] = React.useState(false);

  const portfolioClick = () => {
    // Event('BUTTON', 'Portfolio', 'Clicked Portfolio Button');
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const classes = useStyles();

  return (
    <div>
      {/* <div className={classes.root}> */}
      <AppBar position="static">
        <Toolbar>
          {/* <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
          >
            <MenuIcon />
          </IconButton> */}
          <Typography variant="h6" align="left" className={classes.title}>
            Iñaki McKearney
          </Typography>
          <Button
            // onClick={() => Event('BUTTON', 'CV', 'Clicked CV Button')}
            href="https://docs.google.com/document/d/e/2PACX-1vQfIvbXHsm3zlnOhAZvoDHacas1BPD9x17Bscv2TFUoD3N-MXLkhGjOoS1_uAwd9aiTeetF9jSDOEZB/pub"
            target="_blank"
            color="inherit"
          >
            CV
          </Button>
          <Button
            onClick={portfolioClick}
            color="inherit"
            title="Section currently in development, currently links to GitHub"
          >
            Projects
          </Button>
        </Toolbar>
      </AppBar>

      <Snackbar
        open={open}
        anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="warning">
          Page under construction.
          <br /> Please visit my{' '}
          <a
            onClick={() =>
              Event(
                'BUTTON',
                'Portfolio-GitHub',
                'Clicked Portfolio, then GitHub link'
              )
            }
            href="https://github.com/Inaki-McKearney"
            target="_blank"
            rel="noopener noreferrer"
          >
            GitHub
          </a>{' '}
          in the meanwhile
        </Alert>
      </Snackbar>
    </div>
  );
}

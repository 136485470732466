import React from 'react';
import './App.css';

// import { PageView, initGA } from './components/Tracking';
import { About } from './components/About';
import NavBar from './components/NavBar';
import SocialLinks from './components/SocialLinks';

function App() {
  // useEffect(() => {
  //   initGA('UA-168606135-1');
  //   PageView();
  // });

  return (
    <div className="App">
      <NavBar />
      <About />
      <SocialLinks />
    </div>
  );
}

export default App;

import React, { Component } from 'react';

export class About extends Component {
  render() {
    return (
      <div id="about">
        <img id="profile" src="placeholder.jpeg" alt="profile" />
        <h1>Iñaki McKearney </h1>
        <h2>Software Engineer</h2>
      </div>
    );
  }
}

export default About;

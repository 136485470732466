import React from 'react';
import GitHubIcon from '@material-ui/icons/GitHub';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import EmailIcon from '@material-ui/icons/Email';

// import { Event } from './Tracking';

export default function SocialLinks() {
  return (
    <div className="social-links">
      {/*Github*/}
      <a
        // onClick={() => Event('BUTTON', 'GitHub', 'Clicked GitHub Button')}
        href="https://github.com/Inaki-McKearney"
        target="_blank"
        rel="noopener noreferrer"
      >
        <GitHubIcon htmlColor="purple" fontSize="large" />
      </a>

      {/*LinkedIn*/}
      <a
        // onClick={() => Event('BUTTON', 'LinkedIn', 'Clicked LinkedIn Button')}
        href="https://linkedin.com/in/Iñaki-McKearney-b049a0150"
        target="_blank"
        rel="noopener noreferrer"
      >
        <LinkedInIcon htmlColor="purple" fontSize="large" />
      </a>

      {/*Facebook*/}
      <a
        // onClick={() => Event('BUTTON', 'Facebook', 'Clicked Facebook Button')}
        href="https://www.facebook.com/inaki.mckearney/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FacebookIcon htmlColor="purple" fontSize="large" />
      </a>

      {/*Instagram*/}
      <a
        // onClick={() => Event('BUTTON', 'Instagram', 'Clicked Instagram Button')}
        href="https://www.instagram.com/inakimckearney/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <InstagramIcon htmlColor="purple" fontSize="large" />
      </a>

      {/*Email*/}
      <a
        // onClick={() => Event('BUTTON', 'Email', 'Clicked Email Button')}
        href="mailto:inaki.mckearney@gmail.com"
      >
        <EmailIcon htmlColor="purple" fontSize="large" />{' '}
        {/*or EmailOutlinedIcon*/}
      </a>
    </div>
  );
}
